import { default as indexpGOO8zzi5jMeta } from "/builds/robustastudio/mazaya/parfois-v3/pages/[...slug]/index.vue?macro=true";
import { default as aboutdl8OUwfqhFMeta } from "/builds/robustastudio/mazaya/parfois-v3/pages/about.vue?macro=true";
import { default as editTnzUKsRptkMeta } from "/builds/robustastudio/mazaya/parfois-v3/pages/account/addresses/[id]/edit.vue?macro=true";
import { default as indexNBX5xnwMpLMeta } from "/builds/robustastudio/mazaya/parfois-v3/pages/account/addresses/index.vue?macro=true";
import { default as newrBsDtyJe8RMeta } from "/builds/robustastudio/mazaya/parfois-v3/pages/account/addresses/new.vue?macro=true";
import { default as email3qpKYl5IgyMeta } from "/builds/robustastudio/mazaya/parfois-v3/pages/account/edit/email.vue?macro=true";
import { default as indextv3GpBISXpMeta } from "/builds/robustastudio/mazaya/parfois-v3/pages/account/edit/index.vue?macro=true";
import { default as passwordoc4ILWQPktMeta } from "/builds/robustastudio/mazaya/parfois-v3/pages/account/edit/password.vue?macro=true";
import { default as indexoEAx5KvWh2Meta } from "/builds/robustastudio/mazaya/parfois-v3/pages/account/index.vue?macro=true";
import { default as _91id_93ExRLNw78DXMeta } from "/builds/robustastudio/mazaya/parfois-v3/pages/account/orders/[id].vue?macro=true";
import { default as indextkEk3X8ZlYMeta } from "/builds/robustastudio/mazaya/parfois-v3/pages/account/orders/index.vue?macro=true";
import { default as wishlistwa34z3cz8qMeta } from "/builds/robustastudio/mazaya/parfois-v3/pages/account/wishlist.vue?macro=true";
import { default as accountgPQHcQQnFfMeta } from "/builds/robustastudio/mazaya/parfois-v3/pages/account.vue?macro=true";
import { default as recover8uPl0n7uc3Meta } from "/builds/robustastudio/mazaya/parfois-v3/pages/auth/recover.vue?macro=true";
import { default as _91slug_93CUe5B4Uz28Meta } from "/builds/robustastudio/mazaya/parfois-v3/pages/brands/[slug].vue?macro=true";
import { default as indexotNaff1hilMeta } from "/builds/robustastudio/mazaya/parfois-v3/pages/brands/index.vue?macro=true";
import { default as cartcB6NIkILi0Meta } from "/builds/robustastudio/mazaya/parfois-v3/pages/cart.vue?macro=true";
import { default as guest_45shippingXeon2n98o6Meta } from "/builds/robustastudio/mazaya/parfois-v3/pages/checkout/guest-shipping.vue?macro=true";
import { default as payVjRSkj43pqMeta } from "/builds/robustastudio/mazaya/parfois-v3/pages/checkout/pay.vue?macro=true";
import { default as shippingiBR9hTUgTeMeta } from "/builds/robustastudio/mazaya/parfois-v3/pages/checkout/shipping.vue?macro=true";
import { default as contactOMMjBg6P89Meta } from "/builds/robustastudio/mazaya/parfois-v3/pages/contact.vue?macro=true";
import { default as deals9qmYBhlvLrMeta } from "/builds/robustastudio/mazaya/parfois-v3/pages/deals.vue?macro=true";
import { default as errorkiRkoWyHjCMeta } from "/builds/robustastudio/mazaya/parfois-v3/pages/error.vue?macro=true";
import { default as _91slug_93UsZhLzGcCiMeta } from "/builds/robustastudio/mazaya/parfois-v3/pages/faqs/[slug].vue?macro=true";
import { default as indexZuDuRxgpalMeta } from "/builds/robustastudio/mazaya/parfois-v3/pages/faqs/index.vue?macro=true";
import { default as successETERabugjgMeta } from "/builds/robustastudio/mazaya/parfois-v3/pages/guest-order/[id]/success.vue?macro=true";
import { default as index8eg11miObKMeta } from "/builds/robustastudio/mazaya/parfois-v3/pages/index.vue?macro=true";
import { default as loginx8XtTUgJN0Meta } from "/builds/robustastudio/mazaya/parfois-v3/pages/login.vue?macro=true";
import { default as new_45incBHXCQBgVcMeta } from "/builds/robustastudio/mazaya/parfois-v3/pages/new-in.vue?macro=true";
import { default as create_45reviewV6jA9e5uOFMeta } from "/builds/robustastudio/mazaya/parfois-v3/pages/orders/[id]/create-review.vue?macro=true";
import { default as successdtU7MyyrpsMeta } from "/builds/robustastudio/mazaya/parfois-v3/pages/orders/[id]/success.vue?macro=true";
import { default as privacy_45policyUtFTcyO4rnMeta } from "/builds/robustastudio/mazaya/parfois-v3/pages/privacy-policy.vue?macro=true";
import { default as registerBewNLzswWzMeta } from "/builds/robustastudio/mazaya/parfois-v3/pages/register.vue?macro=true";
import { default as _91name_93ZrR7YsXz1DMeta } from "/builds/robustastudio/mazaya/parfois-v3/pages/search/[name].vue?macro=true";
import { default as searchKVqzyx6XD0Meta } from "/builds/robustastudio/mazaya/parfois-v3/pages/search.vue?macro=true";
import { default as storesj3XNWg6MTsMeta } from "/builds/robustastudio/mazaya/parfois-v3/pages/stores.vue?macro=true";
import { default as terms_45and_45conditionsGoueUeFkIrMeta } from "/builds/robustastudio/mazaya/parfois-v3/pages/terms-and-conditions.vue?macro=true";
import { default as test4G5JifXFY0Meta } from "/builds/robustastudio/mazaya/parfois-v3/pages/test.vue?macro=true";
export default [
  {
    name: indexpGOO8zzi5jMeta?.name ?? "slug___ar",
    path: indexpGOO8zzi5jMeta?.path ?? "/ar/:slug(.*)*",
    meta: indexpGOO8zzi5jMeta || {},
    alias: indexpGOO8zzi5jMeta?.alias || [],
    redirect: indexpGOO8zzi5jMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexpGOO8zzi5jMeta?.name ?? "slug",
    path: indexpGOO8zzi5jMeta?.path ?? "/:slug(.*)*",
    meta: indexpGOO8zzi5jMeta || {},
    alias: indexpGOO8zzi5jMeta?.alias || [],
    redirect: indexpGOO8zzi5jMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexpGOO8zzi5jMeta?.name ?? "slug___en",
    path: indexpGOO8zzi5jMeta?.path ?? "/en/:slug(.*)*",
    meta: indexpGOO8zzi5jMeta || {},
    alias: indexpGOO8zzi5jMeta?.alias || [],
    redirect: indexpGOO8zzi5jMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: aboutdl8OUwfqhFMeta?.name ?? "about___ar",
    path: aboutdl8OUwfqhFMeta?.path ?? "/ar/about",
    meta: aboutdl8OUwfqhFMeta || {},
    alias: aboutdl8OUwfqhFMeta?.alias || [],
    redirect: aboutdl8OUwfqhFMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aboutdl8OUwfqhFMeta?.name ?? "about",
    path: aboutdl8OUwfqhFMeta?.path ?? "/about",
    meta: aboutdl8OUwfqhFMeta || {},
    alias: aboutdl8OUwfqhFMeta?.alias || [],
    redirect: aboutdl8OUwfqhFMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aboutdl8OUwfqhFMeta?.name ?? "about___en",
    path: aboutdl8OUwfqhFMeta?.path ?? "/en/about",
    meta: aboutdl8OUwfqhFMeta || {},
    alias: aboutdl8OUwfqhFMeta?.alias || [],
    redirect: aboutdl8OUwfqhFMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/about.vue").then(m => m.default || m)
  },
  {
    path: accountgPQHcQQnFfMeta?.path ?? "/ar/account",
    children: [
  {
    name: editTnzUKsRptkMeta?.name ?? "account-addresses-id-edit___ar",
    path: editTnzUKsRptkMeta?.path ?? "addresses/:id()/edit",
    meta: editTnzUKsRptkMeta || {},
    alias: editTnzUKsRptkMeta?.alias || [],
    redirect: editTnzUKsRptkMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/account/addresses/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexNBX5xnwMpLMeta?.name ?? "account-addresses___ar",
    path: indexNBX5xnwMpLMeta?.path ?? "addresses",
    meta: indexNBX5xnwMpLMeta || {},
    alias: indexNBX5xnwMpLMeta?.alias || [],
    redirect: indexNBX5xnwMpLMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/account/addresses/index.vue").then(m => m.default || m)
  },
  {
    name: newrBsDtyJe8RMeta?.name ?? "account-addresses-new___ar",
    path: newrBsDtyJe8RMeta?.path ?? "addresses/new",
    meta: newrBsDtyJe8RMeta || {},
    alias: newrBsDtyJe8RMeta?.alias || [],
    redirect: newrBsDtyJe8RMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/account/addresses/new.vue").then(m => m.default || m)
  },
  {
    name: email3qpKYl5IgyMeta?.name ?? "account-edit-email___ar",
    path: email3qpKYl5IgyMeta?.path ?? "edit/email",
    meta: email3qpKYl5IgyMeta || {},
    alias: email3qpKYl5IgyMeta?.alias || [],
    redirect: email3qpKYl5IgyMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/account/edit/email.vue").then(m => m.default || m)
  },
  {
    name: indextv3GpBISXpMeta?.name ?? "account-edit___ar",
    path: indextv3GpBISXpMeta?.path ?? "edit",
    meta: indextv3GpBISXpMeta || {},
    alias: indextv3GpBISXpMeta?.alias || [],
    redirect: indextv3GpBISXpMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/account/edit/index.vue").then(m => m.default || m)
  },
  {
    name: passwordoc4ILWQPktMeta?.name ?? "account-edit-password___ar",
    path: passwordoc4ILWQPktMeta?.path ?? "edit/password",
    meta: passwordoc4ILWQPktMeta || {},
    alias: passwordoc4ILWQPktMeta?.alias || [],
    redirect: passwordoc4ILWQPktMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/account/edit/password.vue").then(m => m.default || m)
  },
  {
    name: indexoEAx5KvWh2Meta?.name ?? "account___ar",
    path: indexoEAx5KvWh2Meta?.path ?? "",
    meta: indexoEAx5KvWh2Meta || {},
    alias: indexoEAx5KvWh2Meta?.alias || [],
    redirect: indexoEAx5KvWh2Meta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ExRLNw78DXMeta?.name ?? "account-orders-id___ar",
    path: _91id_93ExRLNw78DXMeta?.path ?? "orders/:id()",
    meta: _91id_93ExRLNw78DXMeta || {},
    alias: _91id_93ExRLNw78DXMeta?.alias || [],
    redirect: _91id_93ExRLNw78DXMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/account/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: indextkEk3X8ZlYMeta?.name ?? "account-orders___ar",
    path: indextkEk3X8ZlYMeta?.path ?? "orders",
    meta: indextkEk3X8ZlYMeta || {},
    alias: indextkEk3X8ZlYMeta?.alias || [],
    redirect: indextkEk3X8ZlYMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: wishlistwa34z3cz8qMeta?.name ?? "account-wishlist___ar",
    path: wishlistwa34z3cz8qMeta?.path ?? "wishlist",
    meta: wishlistwa34z3cz8qMeta || {},
    alias: wishlistwa34z3cz8qMeta?.alias || [],
    redirect: wishlistwa34z3cz8qMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/account/wishlist.vue").then(m => m.default || m)
  }
],
    name: accountgPQHcQQnFfMeta?.name ?? undefined,
    meta: accountgPQHcQQnFfMeta || {},
    alias: accountgPQHcQQnFfMeta?.alias || [],
    redirect: accountgPQHcQQnFfMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/account.vue").then(m => m.default || m)
  },
  {
    path: accountgPQHcQQnFfMeta?.path ?? "/account",
    children: [
  {
    name: editTnzUKsRptkMeta?.name ?? "account-addresses-id-edit",
    path: editTnzUKsRptkMeta?.path ?? "addresses/:id()/edit",
    meta: editTnzUKsRptkMeta || {},
    alias: editTnzUKsRptkMeta?.alias || [],
    redirect: editTnzUKsRptkMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/account/addresses/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexNBX5xnwMpLMeta?.name ?? "account-addresses",
    path: indexNBX5xnwMpLMeta?.path ?? "addresses",
    meta: indexNBX5xnwMpLMeta || {},
    alias: indexNBX5xnwMpLMeta?.alias || [],
    redirect: indexNBX5xnwMpLMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/account/addresses/index.vue").then(m => m.default || m)
  },
  {
    name: newrBsDtyJe8RMeta?.name ?? "account-addresses-new",
    path: newrBsDtyJe8RMeta?.path ?? "addresses/new",
    meta: newrBsDtyJe8RMeta || {},
    alias: newrBsDtyJe8RMeta?.alias || [],
    redirect: newrBsDtyJe8RMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/account/addresses/new.vue").then(m => m.default || m)
  },
  {
    name: email3qpKYl5IgyMeta?.name ?? "account-edit-email",
    path: email3qpKYl5IgyMeta?.path ?? "edit/email",
    meta: email3qpKYl5IgyMeta || {},
    alias: email3qpKYl5IgyMeta?.alias || [],
    redirect: email3qpKYl5IgyMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/account/edit/email.vue").then(m => m.default || m)
  },
  {
    name: indextv3GpBISXpMeta?.name ?? "account-edit",
    path: indextv3GpBISXpMeta?.path ?? "edit",
    meta: indextv3GpBISXpMeta || {},
    alias: indextv3GpBISXpMeta?.alias || [],
    redirect: indextv3GpBISXpMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/account/edit/index.vue").then(m => m.default || m)
  },
  {
    name: passwordoc4ILWQPktMeta?.name ?? "account-edit-password",
    path: passwordoc4ILWQPktMeta?.path ?? "edit/password",
    meta: passwordoc4ILWQPktMeta || {},
    alias: passwordoc4ILWQPktMeta?.alias || [],
    redirect: passwordoc4ILWQPktMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/account/edit/password.vue").then(m => m.default || m)
  },
  {
    name: indexoEAx5KvWh2Meta?.name ?? "account",
    path: indexoEAx5KvWh2Meta?.path ?? "",
    meta: indexoEAx5KvWh2Meta || {},
    alias: indexoEAx5KvWh2Meta?.alias || [],
    redirect: indexoEAx5KvWh2Meta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ExRLNw78DXMeta?.name ?? "account-orders-id",
    path: _91id_93ExRLNw78DXMeta?.path ?? "orders/:id()",
    meta: _91id_93ExRLNw78DXMeta || {},
    alias: _91id_93ExRLNw78DXMeta?.alias || [],
    redirect: _91id_93ExRLNw78DXMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/account/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: indextkEk3X8ZlYMeta?.name ?? "account-orders",
    path: indextkEk3X8ZlYMeta?.path ?? "orders",
    meta: indextkEk3X8ZlYMeta || {},
    alias: indextkEk3X8ZlYMeta?.alias || [],
    redirect: indextkEk3X8ZlYMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: wishlistwa34z3cz8qMeta?.name ?? "account-wishlist",
    path: wishlistwa34z3cz8qMeta?.path ?? "wishlist",
    meta: wishlistwa34z3cz8qMeta || {},
    alias: wishlistwa34z3cz8qMeta?.alias || [],
    redirect: wishlistwa34z3cz8qMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/account/wishlist.vue").then(m => m.default || m)
  }
],
    name: accountgPQHcQQnFfMeta?.name ?? undefined,
    meta: accountgPQHcQQnFfMeta || {},
    alias: accountgPQHcQQnFfMeta?.alias || [],
    redirect: accountgPQHcQQnFfMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/account.vue").then(m => m.default || m)
  },
  {
    path: accountgPQHcQQnFfMeta?.path ?? "/en/account",
    children: [
  {
    name: editTnzUKsRptkMeta?.name ?? "account-addresses-id-edit___en",
    path: editTnzUKsRptkMeta?.path ?? "addresses/:id()/edit",
    meta: editTnzUKsRptkMeta || {},
    alias: editTnzUKsRptkMeta?.alias || [],
    redirect: editTnzUKsRptkMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/account/addresses/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexNBX5xnwMpLMeta?.name ?? "account-addresses___en",
    path: indexNBX5xnwMpLMeta?.path ?? "addresses",
    meta: indexNBX5xnwMpLMeta || {},
    alias: indexNBX5xnwMpLMeta?.alias || [],
    redirect: indexNBX5xnwMpLMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/account/addresses/index.vue").then(m => m.default || m)
  },
  {
    name: newrBsDtyJe8RMeta?.name ?? "account-addresses-new___en",
    path: newrBsDtyJe8RMeta?.path ?? "addresses/new",
    meta: newrBsDtyJe8RMeta || {},
    alias: newrBsDtyJe8RMeta?.alias || [],
    redirect: newrBsDtyJe8RMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/account/addresses/new.vue").then(m => m.default || m)
  },
  {
    name: email3qpKYl5IgyMeta?.name ?? "account-edit-email___en",
    path: email3qpKYl5IgyMeta?.path ?? "edit/email",
    meta: email3qpKYl5IgyMeta || {},
    alias: email3qpKYl5IgyMeta?.alias || [],
    redirect: email3qpKYl5IgyMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/account/edit/email.vue").then(m => m.default || m)
  },
  {
    name: indextv3GpBISXpMeta?.name ?? "account-edit___en",
    path: indextv3GpBISXpMeta?.path ?? "edit",
    meta: indextv3GpBISXpMeta || {},
    alias: indextv3GpBISXpMeta?.alias || [],
    redirect: indextv3GpBISXpMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/account/edit/index.vue").then(m => m.default || m)
  },
  {
    name: passwordoc4ILWQPktMeta?.name ?? "account-edit-password___en",
    path: passwordoc4ILWQPktMeta?.path ?? "edit/password",
    meta: passwordoc4ILWQPktMeta || {},
    alias: passwordoc4ILWQPktMeta?.alias || [],
    redirect: passwordoc4ILWQPktMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/account/edit/password.vue").then(m => m.default || m)
  },
  {
    name: indexoEAx5KvWh2Meta?.name ?? "account___en",
    path: indexoEAx5KvWh2Meta?.path ?? "",
    meta: indexoEAx5KvWh2Meta || {},
    alias: indexoEAx5KvWh2Meta?.alias || [],
    redirect: indexoEAx5KvWh2Meta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ExRLNw78DXMeta?.name ?? "account-orders-id___en",
    path: _91id_93ExRLNw78DXMeta?.path ?? "orders/:id()",
    meta: _91id_93ExRLNw78DXMeta || {},
    alias: _91id_93ExRLNw78DXMeta?.alias || [],
    redirect: _91id_93ExRLNw78DXMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/account/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: indextkEk3X8ZlYMeta?.name ?? "account-orders___en",
    path: indextkEk3X8ZlYMeta?.path ?? "orders",
    meta: indextkEk3X8ZlYMeta || {},
    alias: indextkEk3X8ZlYMeta?.alias || [],
    redirect: indextkEk3X8ZlYMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: wishlistwa34z3cz8qMeta?.name ?? "account-wishlist___en",
    path: wishlistwa34z3cz8qMeta?.path ?? "wishlist",
    meta: wishlistwa34z3cz8qMeta || {},
    alias: wishlistwa34z3cz8qMeta?.alias || [],
    redirect: wishlistwa34z3cz8qMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/account/wishlist.vue").then(m => m.default || m)
  }
],
    name: accountgPQHcQQnFfMeta?.name ?? undefined,
    meta: accountgPQHcQQnFfMeta || {},
    alias: accountgPQHcQQnFfMeta?.alias || [],
    redirect: accountgPQHcQQnFfMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/account.vue").then(m => m.default || m)
  },
  {
    name: recover8uPl0n7uc3Meta?.name ?? "auth-recover___ar",
    path: recover8uPl0n7uc3Meta?.path ?? "/ar/auth/recover",
    meta: recover8uPl0n7uc3Meta || {},
    alias: recover8uPl0n7uc3Meta?.alias || [],
    redirect: recover8uPl0n7uc3Meta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/auth/recover.vue").then(m => m.default || m)
  },
  {
    name: recover8uPl0n7uc3Meta?.name ?? "auth-recover",
    path: recover8uPl0n7uc3Meta?.path ?? "/auth/recover",
    meta: recover8uPl0n7uc3Meta || {},
    alias: recover8uPl0n7uc3Meta?.alias || [],
    redirect: recover8uPl0n7uc3Meta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/auth/recover.vue").then(m => m.default || m)
  },
  {
    name: recover8uPl0n7uc3Meta?.name ?? "auth-recover___en",
    path: recover8uPl0n7uc3Meta?.path ?? "/en/auth/recover",
    meta: recover8uPl0n7uc3Meta || {},
    alias: recover8uPl0n7uc3Meta?.alias || [],
    redirect: recover8uPl0n7uc3Meta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/auth/recover.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93CUe5B4Uz28Meta?.name ?? "brands-slug___ar",
    path: _91slug_93CUe5B4Uz28Meta?.path ?? "/ar/brands/:slug()",
    meta: _91slug_93CUe5B4Uz28Meta || {},
    alias: _91slug_93CUe5B4Uz28Meta?.alias || [],
    redirect: _91slug_93CUe5B4Uz28Meta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/brands/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93CUe5B4Uz28Meta?.name ?? "brands-slug",
    path: _91slug_93CUe5B4Uz28Meta?.path ?? "/brands/:slug()",
    meta: _91slug_93CUe5B4Uz28Meta || {},
    alias: _91slug_93CUe5B4Uz28Meta?.alias || [],
    redirect: _91slug_93CUe5B4Uz28Meta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/brands/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93CUe5B4Uz28Meta?.name ?? "brands-slug___en",
    path: _91slug_93CUe5B4Uz28Meta?.path ?? "/en/brands/:slug()",
    meta: _91slug_93CUe5B4Uz28Meta || {},
    alias: _91slug_93CUe5B4Uz28Meta?.alias || [],
    redirect: _91slug_93CUe5B4Uz28Meta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/brands/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexotNaff1hilMeta?.name ?? "brands___ar",
    path: indexotNaff1hilMeta?.path ?? "/ar/brands",
    meta: indexotNaff1hilMeta || {},
    alias: indexotNaff1hilMeta?.alias || [],
    redirect: indexotNaff1hilMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/brands/index.vue").then(m => m.default || m)
  },
  {
    name: indexotNaff1hilMeta?.name ?? "brands",
    path: indexotNaff1hilMeta?.path ?? "/brands",
    meta: indexotNaff1hilMeta || {},
    alias: indexotNaff1hilMeta?.alias || [],
    redirect: indexotNaff1hilMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/brands/index.vue").then(m => m.default || m)
  },
  {
    name: indexotNaff1hilMeta?.name ?? "brands___en",
    path: indexotNaff1hilMeta?.path ?? "/en/brands",
    meta: indexotNaff1hilMeta || {},
    alias: indexotNaff1hilMeta?.alias || [],
    redirect: indexotNaff1hilMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/brands/index.vue").then(m => m.default || m)
  },
  {
    name: cartcB6NIkILi0Meta?.name ?? "cart___ar",
    path: cartcB6NIkILi0Meta?.path ?? "/ar/cart",
    meta: cartcB6NIkILi0Meta || {},
    alias: cartcB6NIkILi0Meta?.alias || [],
    redirect: cartcB6NIkILi0Meta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: cartcB6NIkILi0Meta?.name ?? "cart",
    path: cartcB6NIkILi0Meta?.path ?? "/cart",
    meta: cartcB6NIkILi0Meta || {},
    alias: cartcB6NIkILi0Meta?.alias || [],
    redirect: cartcB6NIkILi0Meta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: cartcB6NIkILi0Meta?.name ?? "cart___en",
    path: cartcB6NIkILi0Meta?.path ?? "/en/cart",
    meta: cartcB6NIkILi0Meta || {},
    alias: cartcB6NIkILi0Meta?.alias || [],
    redirect: cartcB6NIkILi0Meta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: guest_45shippingXeon2n98o6Meta?.name ?? "checkout-guest-shipping___ar",
    path: guest_45shippingXeon2n98o6Meta?.path ?? "/ar/checkout/guest-shipping",
    meta: guest_45shippingXeon2n98o6Meta || {},
    alias: guest_45shippingXeon2n98o6Meta?.alias || [],
    redirect: guest_45shippingXeon2n98o6Meta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/checkout/guest-shipping.vue").then(m => m.default || m)
  },
  {
    name: guest_45shippingXeon2n98o6Meta?.name ?? "checkout-guest-shipping",
    path: guest_45shippingXeon2n98o6Meta?.path ?? "/checkout/guest-shipping",
    meta: guest_45shippingXeon2n98o6Meta || {},
    alias: guest_45shippingXeon2n98o6Meta?.alias || [],
    redirect: guest_45shippingXeon2n98o6Meta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/checkout/guest-shipping.vue").then(m => m.default || m)
  },
  {
    name: guest_45shippingXeon2n98o6Meta?.name ?? "checkout-guest-shipping___en",
    path: guest_45shippingXeon2n98o6Meta?.path ?? "/en/checkout/guest-shipping",
    meta: guest_45shippingXeon2n98o6Meta || {},
    alias: guest_45shippingXeon2n98o6Meta?.alias || [],
    redirect: guest_45shippingXeon2n98o6Meta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/checkout/guest-shipping.vue").then(m => m.default || m)
  },
  {
    name: payVjRSkj43pqMeta?.name ?? "checkout-pay___ar",
    path: payVjRSkj43pqMeta?.path ?? "/ar/checkout/pay",
    meta: payVjRSkj43pqMeta || {},
    alias: payVjRSkj43pqMeta?.alias || [],
    redirect: payVjRSkj43pqMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/checkout/pay.vue").then(m => m.default || m)
  },
  {
    name: payVjRSkj43pqMeta?.name ?? "checkout-pay",
    path: payVjRSkj43pqMeta?.path ?? "/checkout/pay",
    meta: payVjRSkj43pqMeta || {},
    alias: payVjRSkj43pqMeta?.alias || [],
    redirect: payVjRSkj43pqMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/checkout/pay.vue").then(m => m.default || m)
  },
  {
    name: payVjRSkj43pqMeta?.name ?? "checkout-pay___en",
    path: payVjRSkj43pqMeta?.path ?? "/en/checkout/pay",
    meta: payVjRSkj43pqMeta || {},
    alias: payVjRSkj43pqMeta?.alias || [],
    redirect: payVjRSkj43pqMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/checkout/pay.vue").then(m => m.default || m)
  },
  {
    name: shippingiBR9hTUgTeMeta?.name ?? "checkout-shipping___ar",
    path: shippingiBR9hTUgTeMeta?.path ?? "/ar/checkout/shipping",
    meta: shippingiBR9hTUgTeMeta || {},
    alias: shippingiBR9hTUgTeMeta?.alias || [],
    redirect: shippingiBR9hTUgTeMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/checkout/shipping.vue").then(m => m.default || m)
  },
  {
    name: shippingiBR9hTUgTeMeta?.name ?? "checkout-shipping",
    path: shippingiBR9hTUgTeMeta?.path ?? "/checkout/shipping",
    meta: shippingiBR9hTUgTeMeta || {},
    alias: shippingiBR9hTUgTeMeta?.alias || [],
    redirect: shippingiBR9hTUgTeMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/checkout/shipping.vue").then(m => m.default || m)
  },
  {
    name: shippingiBR9hTUgTeMeta?.name ?? "checkout-shipping___en",
    path: shippingiBR9hTUgTeMeta?.path ?? "/en/checkout/shipping",
    meta: shippingiBR9hTUgTeMeta || {},
    alias: shippingiBR9hTUgTeMeta?.alias || [],
    redirect: shippingiBR9hTUgTeMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/checkout/shipping.vue").then(m => m.default || m)
  },
  {
    name: contactOMMjBg6P89Meta?.name ?? "contact___ar",
    path: contactOMMjBg6P89Meta?.path ?? "/ar/contact",
    meta: contactOMMjBg6P89Meta || {},
    alias: contactOMMjBg6P89Meta?.alias || [],
    redirect: contactOMMjBg6P89Meta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactOMMjBg6P89Meta?.name ?? "contact",
    path: contactOMMjBg6P89Meta?.path ?? "/contact",
    meta: contactOMMjBg6P89Meta || {},
    alias: contactOMMjBg6P89Meta?.alias || [],
    redirect: contactOMMjBg6P89Meta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactOMMjBg6P89Meta?.name ?? "contact___en",
    path: contactOMMjBg6P89Meta?.path ?? "/en/contact",
    meta: contactOMMjBg6P89Meta || {},
    alias: contactOMMjBg6P89Meta?.alias || [],
    redirect: contactOMMjBg6P89Meta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: deals9qmYBhlvLrMeta?.name ?? "deals___ar",
    path: deals9qmYBhlvLrMeta?.path ?? "/ar/deals",
    meta: deals9qmYBhlvLrMeta || {},
    alias: deals9qmYBhlvLrMeta?.alias || [],
    redirect: deals9qmYBhlvLrMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/deals.vue").then(m => m.default || m)
  },
  {
    name: deals9qmYBhlvLrMeta?.name ?? "deals",
    path: deals9qmYBhlvLrMeta?.path ?? "/deals",
    meta: deals9qmYBhlvLrMeta || {},
    alias: deals9qmYBhlvLrMeta?.alias || [],
    redirect: deals9qmYBhlvLrMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/deals.vue").then(m => m.default || m)
  },
  {
    name: deals9qmYBhlvLrMeta?.name ?? "deals___en",
    path: deals9qmYBhlvLrMeta?.path ?? "/en/deals",
    meta: deals9qmYBhlvLrMeta || {},
    alias: deals9qmYBhlvLrMeta?.alias || [],
    redirect: deals9qmYBhlvLrMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/deals.vue").then(m => m.default || m)
  },
  {
    name: errorkiRkoWyHjCMeta?.name ?? "error___ar",
    path: errorkiRkoWyHjCMeta?.path ?? "/ar/error",
    meta: errorkiRkoWyHjCMeta || {},
    alias: errorkiRkoWyHjCMeta?.alias || [],
    redirect: errorkiRkoWyHjCMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/error.vue").then(m => m.default || m)
  },
  {
    name: errorkiRkoWyHjCMeta?.name ?? "error",
    path: errorkiRkoWyHjCMeta?.path ?? "/error",
    meta: errorkiRkoWyHjCMeta || {},
    alias: errorkiRkoWyHjCMeta?.alias || [],
    redirect: errorkiRkoWyHjCMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/error.vue").then(m => m.default || m)
  },
  {
    name: errorkiRkoWyHjCMeta?.name ?? "error___en",
    path: errorkiRkoWyHjCMeta?.path ?? "/en/error",
    meta: errorkiRkoWyHjCMeta || {},
    alias: errorkiRkoWyHjCMeta?.alias || [],
    redirect: errorkiRkoWyHjCMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/error.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UsZhLzGcCiMeta?.name ?? "faqs-slug___ar",
    path: _91slug_93UsZhLzGcCiMeta?.path ?? "/ar/faqs/:slug()",
    meta: _91slug_93UsZhLzGcCiMeta || {},
    alias: _91slug_93UsZhLzGcCiMeta?.alias || [],
    redirect: _91slug_93UsZhLzGcCiMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UsZhLzGcCiMeta?.name ?? "faqs-slug",
    path: _91slug_93UsZhLzGcCiMeta?.path ?? "/faqs/:slug()",
    meta: _91slug_93UsZhLzGcCiMeta || {},
    alias: _91slug_93UsZhLzGcCiMeta?.alias || [],
    redirect: _91slug_93UsZhLzGcCiMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UsZhLzGcCiMeta?.name ?? "faqs-slug___en",
    path: _91slug_93UsZhLzGcCiMeta?.path ?? "/en/faqs/:slug()",
    meta: _91slug_93UsZhLzGcCiMeta || {},
    alias: _91slug_93UsZhLzGcCiMeta?.alias || [],
    redirect: _91slug_93UsZhLzGcCiMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexZuDuRxgpalMeta?.name ?? "faqs___ar",
    path: indexZuDuRxgpalMeta?.path ?? "/ar/faqs",
    meta: indexZuDuRxgpalMeta || {},
    alias: indexZuDuRxgpalMeta?.alias || [],
    redirect: indexZuDuRxgpalMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexZuDuRxgpalMeta?.name ?? "faqs",
    path: indexZuDuRxgpalMeta?.path ?? "/faqs",
    meta: indexZuDuRxgpalMeta || {},
    alias: indexZuDuRxgpalMeta?.alias || [],
    redirect: indexZuDuRxgpalMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexZuDuRxgpalMeta?.name ?? "faqs___en",
    path: indexZuDuRxgpalMeta?.path ?? "/en/faqs",
    meta: indexZuDuRxgpalMeta || {},
    alias: indexZuDuRxgpalMeta?.alias || [],
    redirect: indexZuDuRxgpalMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: successETERabugjgMeta?.name ?? "guest-order-id-success___ar",
    path: successETERabugjgMeta?.path ?? "/ar/guest-order/:id()/success",
    meta: successETERabugjgMeta || {},
    alias: successETERabugjgMeta?.alias || [],
    redirect: successETERabugjgMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/guest-order/[id]/success.vue").then(m => m.default || m)
  },
  {
    name: successETERabugjgMeta?.name ?? "guest-order-id-success",
    path: successETERabugjgMeta?.path ?? "/guest-order/:id()/success",
    meta: successETERabugjgMeta || {},
    alias: successETERabugjgMeta?.alias || [],
    redirect: successETERabugjgMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/guest-order/[id]/success.vue").then(m => m.default || m)
  },
  {
    name: successETERabugjgMeta?.name ?? "guest-order-id-success___en",
    path: successETERabugjgMeta?.path ?? "/en/guest-order/:id()/success",
    meta: successETERabugjgMeta || {},
    alias: successETERabugjgMeta?.alias || [],
    redirect: successETERabugjgMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/guest-order/[id]/success.vue").then(m => m.default || m)
  },
  {
    name: index8eg11miObKMeta?.name ?? "index___ar",
    path: index8eg11miObKMeta?.path ?? "/ar",
    meta: index8eg11miObKMeta || {},
    alias: index8eg11miObKMeta?.alias || [],
    redirect: index8eg11miObKMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index8eg11miObKMeta?.name ?? "index",
    path: index8eg11miObKMeta?.path ?? "/",
    meta: index8eg11miObKMeta || {},
    alias: index8eg11miObKMeta?.alias || [],
    redirect: index8eg11miObKMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index8eg11miObKMeta?.name ?? "index___en",
    path: index8eg11miObKMeta?.path ?? "/en",
    meta: index8eg11miObKMeta || {},
    alias: index8eg11miObKMeta?.alias || [],
    redirect: index8eg11miObKMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginx8XtTUgJN0Meta?.name ?? "login___ar",
    path: loginx8XtTUgJN0Meta?.path ?? "/ar/login",
    meta: loginx8XtTUgJN0Meta || {},
    alias: loginx8XtTUgJN0Meta?.alias || [],
    redirect: loginx8XtTUgJN0Meta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginx8XtTUgJN0Meta?.name ?? "login",
    path: loginx8XtTUgJN0Meta?.path ?? "/login",
    meta: loginx8XtTUgJN0Meta || {},
    alias: loginx8XtTUgJN0Meta?.alias || [],
    redirect: loginx8XtTUgJN0Meta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginx8XtTUgJN0Meta?.name ?? "login___en",
    path: loginx8XtTUgJN0Meta?.path ?? "/en/login",
    meta: loginx8XtTUgJN0Meta || {},
    alias: loginx8XtTUgJN0Meta?.alias || [],
    redirect: loginx8XtTUgJN0Meta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/login.vue").then(m => m.default || m)
  },
  {
    name: new_45incBHXCQBgVcMeta?.name ?? "new-in___ar",
    path: new_45incBHXCQBgVcMeta?.path ?? "/ar/new-in",
    meta: new_45incBHXCQBgVcMeta || {},
    alias: new_45incBHXCQBgVcMeta?.alias || [],
    redirect: new_45incBHXCQBgVcMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/new-in.vue").then(m => m.default || m)
  },
  {
    name: new_45incBHXCQBgVcMeta?.name ?? "new-in",
    path: new_45incBHXCQBgVcMeta?.path ?? "/new-in",
    meta: new_45incBHXCQBgVcMeta || {},
    alias: new_45incBHXCQBgVcMeta?.alias || [],
    redirect: new_45incBHXCQBgVcMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/new-in.vue").then(m => m.default || m)
  },
  {
    name: new_45incBHXCQBgVcMeta?.name ?? "new-in___en",
    path: new_45incBHXCQBgVcMeta?.path ?? "/en/new-in",
    meta: new_45incBHXCQBgVcMeta || {},
    alias: new_45incBHXCQBgVcMeta?.alias || [],
    redirect: new_45incBHXCQBgVcMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/new-in.vue").then(m => m.default || m)
  },
  {
    name: create_45reviewV6jA9e5uOFMeta?.name ?? "orders-id-create-review___ar",
    path: create_45reviewV6jA9e5uOFMeta?.path ?? "/ar/orders/:id()/create-review",
    meta: create_45reviewV6jA9e5uOFMeta || {},
    alias: create_45reviewV6jA9e5uOFMeta?.alias || [],
    redirect: create_45reviewV6jA9e5uOFMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/orders/[id]/create-review.vue").then(m => m.default || m)
  },
  {
    name: create_45reviewV6jA9e5uOFMeta?.name ?? "orders-id-create-review",
    path: create_45reviewV6jA9e5uOFMeta?.path ?? "/orders/:id()/create-review",
    meta: create_45reviewV6jA9e5uOFMeta || {},
    alias: create_45reviewV6jA9e5uOFMeta?.alias || [],
    redirect: create_45reviewV6jA9e5uOFMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/orders/[id]/create-review.vue").then(m => m.default || m)
  },
  {
    name: create_45reviewV6jA9e5uOFMeta?.name ?? "orders-id-create-review___en",
    path: create_45reviewV6jA9e5uOFMeta?.path ?? "/en/orders/:id()/create-review",
    meta: create_45reviewV6jA9e5uOFMeta || {},
    alias: create_45reviewV6jA9e5uOFMeta?.alias || [],
    redirect: create_45reviewV6jA9e5uOFMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/orders/[id]/create-review.vue").then(m => m.default || m)
  },
  {
    name: successdtU7MyyrpsMeta?.name ?? "orders-id-success___ar",
    path: successdtU7MyyrpsMeta?.path ?? "/ar/orders/:id()/success",
    meta: successdtU7MyyrpsMeta || {},
    alias: successdtU7MyyrpsMeta?.alias || [],
    redirect: successdtU7MyyrpsMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/orders/[id]/success.vue").then(m => m.default || m)
  },
  {
    name: successdtU7MyyrpsMeta?.name ?? "orders-id-success",
    path: successdtU7MyyrpsMeta?.path ?? "/orders/:id()/success",
    meta: successdtU7MyyrpsMeta || {},
    alias: successdtU7MyyrpsMeta?.alias || [],
    redirect: successdtU7MyyrpsMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/orders/[id]/success.vue").then(m => m.default || m)
  },
  {
    name: successdtU7MyyrpsMeta?.name ?? "orders-id-success___en",
    path: successdtU7MyyrpsMeta?.path ?? "/en/orders/:id()/success",
    meta: successdtU7MyyrpsMeta || {},
    alias: successdtU7MyyrpsMeta?.alias || [],
    redirect: successdtU7MyyrpsMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/orders/[id]/success.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyUtFTcyO4rnMeta?.name ?? "privacy-policy___ar",
    path: privacy_45policyUtFTcyO4rnMeta?.path ?? "/ar/privacy-policy",
    meta: privacy_45policyUtFTcyO4rnMeta || {},
    alias: privacy_45policyUtFTcyO4rnMeta?.alias || [],
    redirect: privacy_45policyUtFTcyO4rnMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyUtFTcyO4rnMeta?.name ?? "privacy-policy",
    path: privacy_45policyUtFTcyO4rnMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyUtFTcyO4rnMeta || {},
    alias: privacy_45policyUtFTcyO4rnMeta?.alias || [],
    redirect: privacy_45policyUtFTcyO4rnMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyUtFTcyO4rnMeta?.name ?? "privacy-policy___en",
    path: privacy_45policyUtFTcyO4rnMeta?.path ?? "/en/privacy-policy",
    meta: privacy_45policyUtFTcyO4rnMeta || {},
    alias: privacy_45policyUtFTcyO4rnMeta?.alias || [],
    redirect: privacy_45policyUtFTcyO4rnMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: registerBewNLzswWzMeta?.name ?? "register___ar",
    path: registerBewNLzswWzMeta?.path ?? "/ar/register",
    meta: registerBewNLzswWzMeta || {},
    alias: registerBewNLzswWzMeta?.alias || [],
    redirect: registerBewNLzswWzMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerBewNLzswWzMeta?.name ?? "register",
    path: registerBewNLzswWzMeta?.path ?? "/register",
    meta: registerBewNLzswWzMeta || {},
    alias: registerBewNLzswWzMeta?.alias || [],
    redirect: registerBewNLzswWzMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerBewNLzswWzMeta?.name ?? "register___en",
    path: registerBewNLzswWzMeta?.path ?? "/en/register",
    meta: registerBewNLzswWzMeta || {},
    alias: registerBewNLzswWzMeta?.alias || [],
    redirect: registerBewNLzswWzMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/register.vue").then(m => m.default || m)
  },
  {
    name: searchKVqzyx6XD0Meta?.name ?? "search___ar",
    path: searchKVqzyx6XD0Meta?.path ?? "/ar/search",
    children: [
  {
    name: _91name_93ZrR7YsXz1DMeta?.name ?? "search-name___ar",
    path: _91name_93ZrR7YsXz1DMeta?.path ?? ":name()",
    meta: _91name_93ZrR7YsXz1DMeta || {},
    alias: _91name_93ZrR7YsXz1DMeta?.alias || [],
    redirect: _91name_93ZrR7YsXz1DMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/search/[name].vue").then(m => m.default || m)
  }
],
    meta: searchKVqzyx6XD0Meta || {},
    alias: searchKVqzyx6XD0Meta?.alias || [],
    redirect: searchKVqzyx6XD0Meta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/search.vue").then(m => m.default || m)
  },
  {
    name: searchKVqzyx6XD0Meta?.name ?? "search",
    path: searchKVqzyx6XD0Meta?.path ?? "/search",
    children: [
  {
    name: _91name_93ZrR7YsXz1DMeta?.name ?? "search-name",
    path: _91name_93ZrR7YsXz1DMeta?.path ?? ":name()",
    meta: _91name_93ZrR7YsXz1DMeta || {},
    alias: _91name_93ZrR7YsXz1DMeta?.alias || [],
    redirect: _91name_93ZrR7YsXz1DMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/search/[name].vue").then(m => m.default || m)
  }
],
    meta: searchKVqzyx6XD0Meta || {},
    alias: searchKVqzyx6XD0Meta?.alias || [],
    redirect: searchKVqzyx6XD0Meta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/search.vue").then(m => m.default || m)
  },
  {
    name: searchKVqzyx6XD0Meta?.name ?? "search___en",
    path: searchKVqzyx6XD0Meta?.path ?? "/en/search",
    children: [
  {
    name: _91name_93ZrR7YsXz1DMeta?.name ?? "search-name___en",
    path: _91name_93ZrR7YsXz1DMeta?.path ?? ":name()",
    meta: _91name_93ZrR7YsXz1DMeta || {},
    alias: _91name_93ZrR7YsXz1DMeta?.alias || [],
    redirect: _91name_93ZrR7YsXz1DMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/search/[name].vue").then(m => m.default || m)
  }
],
    meta: searchKVqzyx6XD0Meta || {},
    alias: searchKVqzyx6XD0Meta?.alias || [],
    redirect: searchKVqzyx6XD0Meta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/search.vue").then(m => m.default || m)
  },
  {
    name: storesj3XNWg6MTsMeta?.name ?? "stores___ar",
    path: storesj3XNWg6MTsMeta?.path ?? "/ar/stores",
    meta: storesj3XNWg6MTsMeta || {},
    alias: storesj3XNWg6MTsMeta?.alias || [],
    redirect: storesj3XNWg6MTsMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/stores.vue").then(m => m.default || m)
  },
  {
    name: storesj3XNWg6MTsMeta?.name ?? "stores",
    path: storesj3XNWg6MTsMeta?.path ?? "/stores",
    meta: storesj3XNWg6MTsMeta || {},
    alias: storesj3XNWg6MTsMeta?.alias || [],
    redirect: storesj3XNWg6MTsMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/stores.vue").then(m => m.default || m)
  },
  {
    name: storesj3XNWg6MTsMeta?.name ?? "stores___en",
    path: storesj3XNWg6MTsMeta?.path ?? "/en/stores",
    meta: storesj3XNWg6MTsMeta || {},
    alias: storesj3XNWg6MTsMeta?.alias || [],
    redirect: storesj3XNWg6MTsMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/stores.vue").then(m => m.default || m)
  },
  {
    name: terms_45and_45conditionsGoueUeFkIrMeta?.name ?? "terms-and-conditions___ar",
    path: terms_45and_45conditionsGoueUeFkIrMeta?.path ?? "/ar/terms-and-conditions",
    meta: terms_45and_45conditionsGoueUeFkIrMeta || {},
    alias: terms_45and_45conditionsGoueUeFkIrMeta?.alias || [],
    redirect: terms_45and_45conditionsGoueUeFkIrMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: terms_45and_45conditionsGoueUeFkIrMeta?.name ?? "terms-and-conditions",
    path: terms_45and_45conditionsGoueUeFkIrMeta?.path ?? "/terms-and-conditions",
    meta: terms_45and_45conditionsGoueUeFkIrMeta || {},
    alias: terms_45and_45conditionsGoueUeFkIrMeta?.alias || [],
    redirect: terms_45and_45conditionsGoueUeFkIrMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: terms_45and_45conditionsGoueUeFkIrMeta?.name ?? "terms-and-conditions___en",
    path: terms_45and_45conditionsGoueUeFkIrMeta?.path ?? "/en/terms-and-conditions",
    meta: terms_45and_45conditionsGoueUeFkIrMeta || {},
    alias: terms_45and_45conditionsGoueUeFkIrMeta?.alias || [],
    redirect: terms_45and_45conditionsGoueUeFkIrMeta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: test4G5JifXFY0Meta?.name ?? "test___ar",
    path: test4G5JifXFY0Meta?.path ?? "/ar/test",
    meta: test4G5JifXFY0Meta || {},
    alias: test4G5JifXFY0Meta?.alias || [],
    redirect: test4G5JifXFY0Meta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/test.vue").then(m => m.default || m)
  },
  {
    name: test4G5JifXFY0Meta?.name ?? "test",
    path: test4G5JifXFY0Meta?.path ?? "/test",
    meta: test4G5JifXFY0Meta || {},
    alias: test4G5JifXFY0Meta?.alias || [],
    redirect: test4G5JifXFY0Meta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/test.vue").then(m => m.default || m)
  },
  {
    name: test4G5JifXFY0Meta?.name ?? "test___en",
    path: test4G5JifXFY0Meta?.path ?? "/en/test",
    meta: test4G5JifXFY0Meta || {},
    alias: test4G5JifXFY0Meta?.alias || [],
    redirect: test4G5JifXFY0Meta?.redirect || undefined,
    component: () => import("/builds/robustastudio/mazaya/parfois-v3/pages/test.vue").then(m => m.default || m)
  }
]